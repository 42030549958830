export const CONFIG_DEFAULT_COLORS = ['#a8f9f9', '#f9b7d8', '#c5bcf8', '#f8f596']

export interface Config {
  changeOpinion: boolean
  scanAutoStop: boolean
  defaultSlideTime: number
  startMessage: string
  language: string
  defaultColors: string[]
}
