<script lang="ts">

</script>

<div id="qm-start" class="tab">
  <div class="columns" style="margin: 6em;">
    <div class="column is-one-third">
      <img src="images/QCMCam-logobig.png" alt="logo QCMCam">
    </div>
    <div class="column">
      <h2 class="title">Amusez-vous !</h2>
      <p>Animez vos cours à l'aide de cette application web qui vous permet de sonder rapidement une classe à
        l'aide d'une webcam (ou <span class="has-text-danger">bientôt d'un smartphone</span>) et de cartes distribuées à vos élèves !</p>
      <button class="button mt-4" id="btn-about">En savoir plus</button>
    </div>
  </div>
  <div class="columns" style="margin: 6em;">
    <div class="column">
      <h2 class="title">Créez vos questionnaires</h2>
      <p>Un éditeur simple de questions et réponses vous permet de créer dimplement vos questionnaires. Vous
        pouvez également aller piocher dans la bibliothèque existante pour aller encore plus vite.</p>
    </div>
    <div class="column  is-one-third">
      <img src="images/undraw_content_creator_re_pt5b.svg" alt="Créer vos questionnaires"
        style="height:100%;max-height:200px;">
    </div>
  </div>
  <div class="columns" style="margin: 6em;">
    <div class="column is-one-third">
      <img src="images/undraw_team_re_0bfe.svg" alt="Gérer vos groupes" style="height:100%;max-height:200px;">
    </div>
    <div class="column">
      <h2 class="title">Gérez vos groupes</h2>
      <p>Créez vos groupes de travail facilement en les important depuis vos outils de travail comme pronote.
        Affectez leur des cartes et le tour est joué.</p>
    </div>
  </div>

  <div class="columns" style="margin: 6em;">
    <div class="column">
      <h2 class="title">Analysez les résultats</h2>
      <p>Récupérez les résultats des sondages pour évaluer vos élèves.</p>
    </div>
    <div class="column is-one-third">
      <img src="images/undraw_performance_overview_re_mqrq.svg" alt="logo QCMCam" class="w3-image"
        style="height:100%;max-height:200px;">
    </div>
  </div>
</div>
