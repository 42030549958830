<script lang="ts">
  import HeaderItem from './HeaderItem.svelte'
  import ModalConnect from './ModalConnect.svelte'
  import ModalNewUser from './ModalNewUser.svelte'
  import ModalSettings from './ModalSettings.svelte'

</script>

<header class="flex justify-between noprint">
  <div class="flex flex-row">
    <HeaderItem view="home" id="btn-start">
      <img
        title="Accueil"
        src="images/QCMCam-logo.png"
        alt="logo qcmcam"
        class="h-8"
      />
    </HeaderItem>
    <HeaderItem view="qcm">
      <i class="ri-article-line"></i>
      QCMs
    </HeaderItem>
    <HeaderItem view="home" id="btn-groups">
      <i class="ri-group-line"></i>
      Groupes
    </HeaderItem>
    <HeaderItem view="home" id="btn-statistics">
      <i class="ri-file-chart-line"></i>
      Stats
    </HeaderItem>
    <HeaderItem view="home" id="btn-open-start-qcm">
      <i class="ri-play-line"></i>
      Démarrer
    </HeaderItem>
    <HeaderItem view="home" id="btn-markers">
      Cartes
    </HeaderItem>
    <HeaderItem view="home" id="btn-settings" title="Réglages">
      <i class="ri-settings-3-line"></i>
    </HeaderItem>
    <HeaderItem view="home" id="btn-openusage">
      <i class="ri-group-line"></i>
      Usage libre
    </HeaderItem>
  </div>
  <!--
  <div class="w3-dropdown-hover w3-right">
    <button class="w3-button">
      <i class="ri-earth-line"></i> Langue
    </button>
    <div class="w3-dropdown-content w3-bar-block w3-card-4">
      <a href="#" class="w3-bar-item w3-button">
        <img src="images/flags/flag-fr.png" alt="Drapeau français"> Français
      </a>
      <a href="#" class="w3-bar-item w3-button">
        <img src="images/flags/flag-en.png" alt="English flag"> English
      </a>
      <a href="#" class="w3-bar-item w3-button">
        <img src="images/flags/flag-de.png" alt="Deutsche Flagge"> Deutsch
      </a>
      <a href="#" class="w3-bar-item w3-button">
        <img src="images/flags/flag-it.png" alt="Bandiera italiana"> Italiano
      </a>
      <a href="#" class="w3-bar-item w3-button">
        <img src="images/flags/flag-es.png" alt="Bandera española"> Español
      </a>
    </div>
  </div>-->
</header>
<ModalSettings />
<ModalNewUser />
<ModalConnect />

<style>
  @media print {
    .noprint,
    .noprint * {
        display: none !important;
        padding:0;
        margin:0;
    }
    html,body {
        padding:0;
        margin:0
    }
  }
</style>