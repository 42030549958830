<script lang="ts">
  import type { View } from '../../lib/ts/models/navigation'
  import { goToView } from '../../lib/ts/services/navigation'
  import { currentView } from '../../lib/ts/services/store'
  import Button from '../shared/Button.svelte'

  export let view: View

</script>

<Button
  id="{$$props.id}"
  class="flex flex-grow items-center shrink-0
    {$$props.class ? $$props.class : 'px-4 h-10'}
    {$currentView === view ? 'is-active' : ''}"
  on:click={(mouseEvent) => goToView(mouseEvent, view)}
>
  <slot />
</Button>
