<script lang="ts">
  import ModalCallRoll from './ModalCallRoll.svelte'

</script>

<div id="qm-diaporama-settings" class="is-hidden tab mx-6">
  <h1 class="title is-2 has-text-centered">Paramètres du diaporama</h1>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Mode</b>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="normal" name="qcm-mode-choice"
          id="qcm-mode-choice0" checked="checked">
          <label for="qcm-mode-choice0">Normal</label>
          <p class="help">1 groupe pour 1 QCM</p>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="double" name="qcm-mode-choice"
          id="qcm-mode-choice1">
          <label for="qcm-mode-choice1">Double QCM</label>
          <p class="help">2 QCM pour 2 (sous-)groupes</p>
      </div>
      <div class="column">
        <input type="radio" class="is-checkradio is-success" value="duel" name="qcm-mode-choice"
          id="qcm-mode-choice2">
        <label for="qcm-mode-choice2">Duel de QCM</label>
        <p class="help">2 (sous-)groupes avec le même QCM</p>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column has-text-centered">
        <b>Groupe</b><br>Deux possible<br>
        <select name="group-choice" class="select is-primary is-multiple border border-gray-400" id="select-group-choice" size="5"
          style="width: 50%; overflow: hidden;" multiple="multiple">
        </select><br>
        <button class="button" id="btn-gp-maker">Sous-groupes</button>
      </div>
      <div class="column has-text-centered has-background-light">
        <b>QCM</b><br>
        Multisélection possible<br>
        <select name="qcm-choice" class="select is-info is-multiple border border-gray-400" id="select-qcm-choice" size="5"
          style="width: 95%;" multiple="multiple">
        </select>
      </div>
      <div class="column has-text-centered is-hidden" id="qcm-choice2">
        <b>QCM 2</b><br>
        Multisélection possible<br>
        <select name="qcm-choice0" class="select is-info is-multiple" id="select-qcm-choice0" size="5"
          style="width: 95%;" multiple="multiple">
        </select>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Commencer</b>
        <div class="field">
          &nbsp;<input type="radio" class="is-checkradio is-info" value="slider" name="qcm-start"
            id="qcm-start-choice0" checked="checked">
          <label for="qcm-start-choice0">au démarrage</label><br>
          <input type="radio" class="is-checkradio is-info" value="hidden" name="qcm-start" id="qcm-start-choice1">
          <label for="qcm-start-choice1">diapo caché</label><br>
          <input type="radio" class="is-checkradio is-info" name="qcm-start" id="qcm-start-choice2" value="appel">
          <label for="qcm-start-choice2">par l'appel</label>
        </div>
      </div>
      <div class="column has-background-light">
        <b>Paramètres QCM</b>
        <div class="field">
          <p>Questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" value="0" name="qcm-slides"
            id="qcm-slides-choice0" checked="checked">
          <label for="qcm-slides-choice0">Toutes <span id="qcm-nb-slides">(0)</span></label><br>
          <input type="radio" class="is-checkradio is-info" value="extract" name="qcm-slides"
            id="qcm-slides-choice1">
          <label for="qcm-slides-choice1">un extrait de</label>
          <input type="number" name="qcm-slides-number" id="qcm-slides-number" size="4" min="1" max="20" value="3">
          questions
        </div>
        <div class="field">
          <p>Ordre des questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-questions" id="qcm-order-questions-choice0" checked="checked" value="ordered"><label for="qcm-order-questions-choice0">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-questions" id="qcm-order-questions-choice1" value="random"><label for="qcm-order-questions-choice1" title="si autorisé">aléatoire</label>
        </div>
        <div class="field">
          <p>Ordre des réponses</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-answers" id="qcm-order-answers-choice0" checked="checked" value="ordered"><label for="qcm-order-answers-choice0">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-answers" id="qcm-order-answers-choice1" value="random"><label for="qcm-order-answers-choice1" title="si autorisé">aléatoire (si autorisé)</label>
        </div>
      </div>
      <div class="column is-hidden" id="qcm-questions-choice2">
        <b>Paramètres QCM 2</b>
        <div class="field">
          <p>Questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" value="0" name="qcm-slides0"
            id="qcm-slides-choice00" checked="checked">
          <label for="qcm-slides-choice00">Toutes <span id="qcm-nb-slides0">(0)</span></label><br>
          <input type="radio" class="is-checkradio is-info" value="extract" name="qcm-slides0"
            id="qcm-slides-choice10">
          <label for="qcm-slides-choice10">un extrait de</label>
          <input type="number" name="qcm-slides-number0" id="qcm-slides-number0" size="4" min="1" max="20" value="3">
          questions
        </div>
        <div class="field">
          <p>Ordre des questions</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-questions0"
            id="qcm-order-questions-choice00" checked="checked" value="ordered"><label for="qcm-order-questions-choice00">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-questions0"
            id="qcm-order-questions-choice01" value="random"><label for="qcm-order-questions-choice01" title="si autorisé">aleatoire</label>
        </div>
        <div class="field">
          <p>Ordre des réponses</p>
          &nbsp;<input type="radio" class="is-checkradio is-info" name="qcm-order-answers0"
            id="qcm-order-answers-choice00" checked="checked" value="ordered"><label for="qcm-order-answers-choice00">tel quel</label>
          <input type="radio" class="is-checkradio is-info" name="qcm-order-answers0"
            id="qcm-order-answers-choice01" value="random"><label for="qcm-order-answers-choice01" title="si autorisé">aleatoire</label>
        </div>
      </div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <div class="column">
        <b>Caméra</b>
        <div class="field">
          &nbsp;<input type="radio" class="is-checkradio" value="local" name="camera-mode-choice"
            id="camera-mode-choice0" checked="checked">
          <label for="camera-mode-choice0">Branchée à l'ordinateur</label><br>
          <input type="radio" class="is-checkradio" value="deported" name="camera-mode-choice"
            id="camera-mode-choice1">
          <label for="camera-mode-choice1">Déportée (smartphone...)</label>
        </div>
      </div>
      <div class="column">
        <div>
          Camera : <select id="selectCameraChoice"></select> <button id="btn-refresh-camera" title="Rafraichir la liste des caméras"><i class="ri-refresh-line"></i></button>
        </div>
        <video autoplay muted playsinline id="videotest" class="is-hidden" style="width:150px;margin: 1em 0 0 3em;"></video>
      </div>
    </div>
  </div>
  <footer class="modal-card-foot is-justify-content-center">
    <button class="button is-light is-large" id="btn-start-qcm">
      <img src="images/play-circle-line.svg" alt="Play button">
    </button>
  </footer>
</div>
<ModalCallRoll />
