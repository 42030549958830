<script lang="ts">

</script>

<div class="modal" id="qm-import-groups">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Import de groupes</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-import-groups"></button>
    </header>
    <section class="modal-card-body">
      <label class="label">Fichier à importer</label>
      <div class="file is-centered">
        <label class="file-label">
          <input class="file-input" type="file" id="import-groups-file" name="resume" accept=".txt,.csv">
          <span class="file-cta">
            <span class="file-icon">
              <i class="ri-upload-2-line"></i>
            </span>
            <span class="file-label">
              Fichier…
            </span>
          </span>
          <span class="file-name">Fichier txt ou csv</span>
        </label>
      </div>
      <label class="label">Données copiées/collées</label>
      <textarea id="import-groups-text" class="input" placeholder="Coller ici les données" cols="50" rows="1"></textarea>
    </section>
    <section id="import-2nd" class="modal-card-body is-hidden">
      2de Étape de l'import
    </section>
    <footer class="modal-card-foot">
      <a href="forgotpassword.html">Importer des groupes ?</a>
    </footer>
  </div>
</div>
