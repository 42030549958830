import utils from './services/utils.js'
import Group from './models/group.js'
import storage from './services/storage.js'
import { generateMarker, dicLength } from './services/marker.js'

let lettersColor = '#E0E0E0'
let groups: Group[] = []
let pngContainer: null | HTMLElement = document.getElementById('png-container')

export async function runGenerator (): Promise<void> {
  pngContainer = document.getElementById('png-container')
  await getGroupFromStorageIfAny()
  initializeForms()
  addInteractionsToDom()
  await checkURLIfPrintOneUser()
}

async function getGroupFromStorageIfAny (): Promise<void> {
  if (storage.isAvailable()) {
    storage.db.store_groups.toArray()
      .then(async (result: Array<{ uid: number, data: Group }>) => {
        for (const element of result) {
          await Group.remake(element.data, element.uid).then(async (group: Group) => {
            groups.push(group)
          }).catch((err) => {
            console.log('Pb de chargement du groupe', element.uid, err)
          })
        }
        if (groups.length > 0) populateGroupsSelect('fromMemory')
      })
      .catch(() => {
        console.log('Pb de chargement des groupes')
      })
  }
}
/**
 * Populate the HTMLSelectElement 'classes' with the groups found in storage (fromMemory), uploaded (new) or default (basic)
 * @param action 'basic' | 'new' | 'fromMemory'
 */
function populateGroupsSelect (action: string): void {
  let log = importationStartMessage(action)
  const classesSelectElement = document.getElementById('classes') as HTMLSelectElement
  if (classesSelectElement !== null) {
    emptyGroupsSelectElement()
    addFirstOptionAsTitle(classesSelectElement)
    log += populateWithGroupsOptions(classesSelectElement, action)
    if (action === 'new') displayLog(log)
  }
}

function importationStartMessage (action: string): string {
  if (action === 'new') {
    return 'Import des groupes '
  } else if (action === 'fromMemory') {
    return 'Récupération des groupes en mémoire '
  } else {
    return ''
  }
}

function emptyGroupsSelectElement (): void {
  const classesSelectElement = document.getElementById('classes') as HTMLSelectElement
  if (classesSelectElement !== null) {
    classesSelectElement.classList.remove('hidden')
    const classesOptionsElements = classesSelectElement.getElementsByTagName('option')
    while (classesOptionsElements[0] !== null && typeof classesOptionsElements[0] === 'object') {
      classesSelectElement.removeChild(classesOptionsElements[0])
    }
  }
}

function addFirstOptionAsTitle (classesSelectElement: HTMLSelectElement): void {
  const optionElement = document.createElement('option')
  optionElement.value = '0'
  optionElement.text = 'Sélectionner la classe pour attribuer les noms'
  classesSelectElement.add(optionElement)
}

function populateWithGroupsOptions (classesSelectElement: HTMLSelectElement, action: string): string {
  let log = ''
  if (groups !== undefined) {
    for (const group of groups) {
      if ((action === 'new' || action === 'fromMemory') || (action === 'basic' && group.name !== 'uploaded')) {
        const option = document.createElement('option')
        option.text = group.name
        option.value = String(group.id)
        classesSelectElement.add(option)
        log += '<li>' + group.name + '</li>'
      }
    }
  }
  return log
}

function displayLog (log: string): void {
  const logDiv = document.getElementById('log')
  if (logDiv !== null) logDiv.innerHTML = log + '</ul>'
}

function initializeForms (): void {
  const withname = document.querySelector("[name='withname']") as HTMLInputElement
  if (withname !== null) withname.checked = true
  const markersInputElement = document.querySelector("[name='markers']") as HTMLInputElement
  if (markersInputElement !== null) markersInputElement.checked = true
  const markersperpage = document.querySelector("[name='markersperpage']") as HTMLInputElement
  if (markersperpage !== null) markersperpage.checked = true
  const eltColor: null | HTMLElement = utils.DOM.getById('colorsetter')
  if (eltColor !== null) { (eltColor as HTMLInputElement).value = lettersColor }
  const minId = document.getElementById('minid') as HTMLInputElement
  const maxId = document.getElementById('maxid') as HTMLInputElement
  const mId = document.getElementById('mid') as HTMLInputElement
  if (minId !== null && maxId !== null && mId !== null) {
    minId.max = dicLength
    maxId.max = dicLength
    mId.max = dicLength
  }
}

function addInteractionsToDom (): void {
  // buttons groups import
  // buttons simple/range of markers
  utils.DOM.addAction('myes', 'input', () => {
    utils.DOM.getById('mppyes')?.click()
    displaySelector()
  })
  utils.DOM.addAction('mno', 'input', () => { displaySelector() })
  // buttons size of markers

  utils.DOM.addAction('mppno', 'input', () => {
    utils.DOM.getById('mno')?.click()
    setPrintFormat()
  })
  // color setter
  const colorsetter = utils.DOM.getById('colorsetter')
  if (colorsetter !== null) colorsetter.onchange = (evt) => { if (evt.target !== null) setLettersColor((evt.target as HTMLInputElement).value) }
  // input of simple marker ID
  utils.DOM.addAction('mid', 'input', () => {
    if (pngContainer !== null) {
      pngContainer.innerHTML = ''
    }
    generateRange()
  })
  // inputs of range markers ID
  utils.DOM.addAction('minid', 'input', () => { generateRange() })
  utils.DOM.addAction('maxid', 'input', () => { generateRange() })
  // input file import
  const file = utils.DOM.getById('file')
  if (file !== null) file.onchange = (event) => { openFile(event) }
  // select group
  utils.DOM.addAction('classes', 'input', () => {
    if (utils.forms.getRadioValue('markers') === 'no') {
      generateRange()
    } else {
      if (pngContainer !== null) {
        pngContainer.innerHTML = ''
      }
      generateRange()
    }
  })
}

async function checkURLIfPrintOneUser (): Promise<void> {
  const urlParams = new URL(window.location.toLocaleString()).searchParams
  const id = urlParams.get('id')
  const name = urlParams.get('name')
  if (id !== null && name !== null) {
    const instantGroup = new Group('instant')
    await instantGroup.addStudent(name, '', Number(id))
    if (pngContainer !== null) {
      pngContainer.innerHTML = ''
    }
    appendPNG(generateMarker([instantGroup], lettersColor, Number(id), true))
  } else {
    generateRange()
  }
}

function appendPNG (canvas: HTMLCanvasElement): void {
  if (pngContainer === null) {
    return
  }
  let className = ''
  pngContainer.classList.forEach((value) => {
    if (value.indexOf('s-') === 0 || value.indexOf('svelte-') === 0) {
      className = value
    }
  })
  const img = document.createElement('IMG') as HTMLImageElement
  img.src = canvas.toDataURL('image/png')
  img.className = className
  const div = document.createElement('DIV')
  div.className = 'centeredflex ' + className
  div.appendChild(img)
  pngContainer.appendChild(div)
}

export function displayList (withname: boolean): void {
  const importGroupList = utils.DOM.getById('importGroupList')
  if (importGroupList !== null) {
    if (!withname) {
      importGroupList.classList.remove('hidden')
    } else {
      importGroupList.classList.add('hidden')
      const classes = utils.DOM.getById('classes') as HTMLInputElement
      if (classes !== null) classes.value = '0'
    }
  }
  generateRange()
}

function displaySelector (): void {
  const value = utils.forms.getRadioValue('markers')
  const simple: HTMLElement | null = utils.DOM.getById('generateOne')
  const multi: HTMLElement | null = utils.DOM.getById('generateMulti')
  if (simple !== null && multi !== null) {
    if (value === 'no') {
      simple.classList.add('hidden')
      multi.classList.remove('hidden')
      generateRange()
    } else {
      simple.classList.remove('hidden')
      multi.classList.add('hidden')
      if (pngContainer !== null) {
        pngContainer.innerHTML = ''
      }
      generateRange()
    }
  }
}

function setPrintFormat (): void {
  if (pngContainer !== null) {
    if (utils.forms.getRadioValue('markersperpage') === 'one') {
      pngContainer.classList.remove('twoPerPage')
    } else {
      pngContainer.classList.add('twoPerPage')
    }
  }
}

function setLettersColor (HTMLHexColor: string): void {
  lettersColor = HTMLHexColor
  checkURLIfPrintOneUser().catch((err) => { console.error(err) })
}

function generateRange (): void {
  if (utils.forms.getRadioValue('markers') !== 'no') {
    if (pngContainer !== null) {
      pngContainer.innerHTML = ''
    }
    appendPNG(generateMarker(groups, lettersColor, Number(utils.forms.getValue('mid'))))
    return
  }
  const minElem = document.getElementById('minid')
  const maxElem = document.getElementById('maxid')
  if ((minElem !== null) && (maxElem !== null)) {
    const min = (minElem as HTMLFormElement).value
    const max = (maxElem as HTMLFormElement).value
    if (pngContainer !== null) {
      pngContainer.innerHTML = ''
    }
    for (let i = Number(min); i <= Number(max); i++) {
      appendPNG(generateMarker(groups, lettersColor, i))
    }
  }
}

function openFile (event: Event): void {
  // le fichier est un txt avec lignes au format Numero\tNom[\tclasse] ou Nom seul
  if (event.target !== null) {
    const input = event.target as unknown as HTMLInputElement
    const reader = new FileReader()
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        const result = Group.import(reader.result, [])
        if (result[1]) {
          groups = result[0]
          populateGroupsSelect('new')
          if (storage.isAvailable()) {
            storage.db.store_data.put([{ uid: 'QCMCamGroups', data: result }])
          }
        }
        if (groups[0].name !== 'uploaded') {
          updateMinMax(groups[0].size)
          generateRange()
        }
      }
    }
    if (input.files !== null) {
      const file = input.files[0]
      reader.readAsText(file)
    }
  }
}

function updateMinMax (max: number): void {
  (document.getElementById('minid') as HTMLInputElement).value = '1';
  (document.getElementById('mid') as HTMLInputElement).value = String(max);
  (document.getElementById('maxid') as HTMLInputElement).value = String(max)
}
