<script lang="ts">

</script>

<div class="modal" id="qm-settings">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Réglages</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-settings"></button>
    </header>
    <div class="modal-card-body">
      <label class="label">Vous utilisez actuellement <span id="storagesize"></span> % du stockage disponible sur votre ordinateur.</label>
      <label class="label">Changement d'avis</label>
      <input type="radio" name="changevote" id="changeopinionyes" class="is-checkradio" value="yes" checked>
      <label for="changeopinionyes">Un participant <b>peut</b> changer son vote</label><br>
      <input type="radio" name="changevote" id="changeopinionno" class="is-checkradio" value="no">
      <label for="changeopinionno">Un participant <b>ne peut pas</b> changer son vote</label>
      <br><br>
      <label class="label">Arrêt du scan</label>
      <input type="radio" name="stopscan" id="stopscanyes" value="yes" class="is-checkradio is-info" checked>
      <label for="stopscanyes">Le scan par webcam <b>cesse</b> automatiquement 5 s. après le dernier vote.</label><br>
      <input type="radio" name="stopscan" id="stopscanno" class="is-checkradio is-info" value="no">
      <label for="stopscanno">Le scan par webcam doit être <b>arrêté manuellement</b>.</label>
      <br><br>
      <div class="field is-horizontal">
        <div class="is-normal is-flex">
          <label class="label">Temps d'affichage des questions par défaut</label>
        </div>
        <div class="field is-inline-block mx-2">
          <p class="control has-icons-left has-icons-right">
            <input type="number" id="defaultTime" class="input has-text-right" min="0" max="200" title="0 = illimité" value="15">
            <span class="icon is-left"><i class="ri-timer-line"></i></span>
            <span class="icon is-right">s.</span>
          </p>
        </div>
      </div>
      <label class="label">Message d'introduction du diaporama</label>
      <input type="text" class="input is-link" id="messageFisrtSlide" placeholder="Texte d'introduction">
      <label class="label">Couleurs des questions par défaut</label>
      <div class="field is-horizontal" id="defaultColors">
        <label for="defaultColor0" class="field-label">Réinitialiser</label>
        <button class="button" id="defaultColor0">Défaut</button>
        <label for="defaultColor1" class="field-label">A</label>
        <input type="color" id="defaultColor1" class="input is-link" value="#a8f9f9">
        <label for="defaultColor2" class="field-label">B</label>
        <input type="color" id="defaultColor2" class="input is-link" value="#f9b7d8">
        <label for="defaultColor3" class="field-label">C</label>
        <input type="color" id="defaultColor3" class="input is-link" value="#c5bcf8">
        <label for="defaultColor4" class="field-label">D</label>
        <input type="color" id="defaultColor4" class="input is-link" value="#f8f596">
      </div>
    </div>
  </div>
</div>
