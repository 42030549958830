<script lang="ts">
  import { displayQcmEditor } from '../../lib/ts/services/qcm'
  import { qcms } from '../../lib/ts/services/store'
  import utils from '../../lib/ts/services/utils'
  import QcmsList from '../../lib/ts/models/qcmsList.js'

  export let listQcms: () => void
  export let selectedQcmId: number

  function openQCMFile (qcms: QcmsList, event: Event): void {
    if (event.target !== null) {
      const importModalFoot = document.querySelector('#qcm-import-qcm .has-text-danger')
      const input = event.target as unknown as HTMLInputElement
      const reader = new FileReader()
      reader.onload = function () {
        if (typeof reader.result === 'string') {
          if (input.files !== null) {
            const result = JSON.parse(reader.result)
            if (result.uid !== undefined) {
              if (qcms.importQcmList(reader.result, input.files[0].name)) {
                utils.DOM.removeClass('qm-import-qcm', 'is-active')
              } else {
                (importModalFoot as HTMLElement).innerText = 'Erreur d\'import'
              }
            } else {
              const qcm = qcms.importQcm(reader.result, input.files[0].name)
              if (qcm !== undefined) {
                listQcms()
                selectedQcmId = qcms.list.length - 1
                displayQcmEditor()
                qcms.save()
                utils.DOM.removeClass('qm-import-qcm', 'is-active')
              } else if (importModalFoot !== null) {
                (importModalFoot as HTMLElement).innerText = 'Erreur de format de fichier'
              }
            }
          }
        }
      }
      reader.onerror = function () {
        if (importModalFoot !== null) {
          (importModalFoot as HTMLElement).innerText = 'Erreur de chargement'
        }
        return false
      }
      if (input.files !== null) {
        const file = input.files[0]
        reader.readAsText(file)
      }
    }
  }
function importOnlineFile (qcms: QcmsList, event: Event): void {
  if (event.target !== null) {
    const url = (event.target as HTMLInputElement).value
    let testurl
    // analyse de la validité de l'url
    try {
      testurl = new URL(url)
    } catch (e) {
      return
    }
    const importModalFoot = document.querySelector('#qcm-import-qcm .has-text-danger')
    const pathName = testurl.pathname
    const fileName = pathName.split('/').pop()
    let nomDuFichier = window.prompt('Donnez un nom au QCM :', fileName)
    if (nomDuFichier === null) {
      nomDuFichier = 'QCM importé'
    }
    const reader = new XMLHttpRequest()
    reader.open('GET', 'phploader.php?url=' + encodeURIComponent(url), false)
    reader.onreadystatechange = () => {
      if (reader.readyState === 4) {
        if (reader.status === 200 || reader.status === 0) {
          try {
            const qcm = qcms.importQcm(reader.responseText, nomDuFichier)
            if (qcm !== undefined) {
              listQcms()
              selectedQcmId = qcms.list.length - 1
              displayQcmEditor()
              qcms.save()
              utils.DOM.removeClass('qm-import-qcm', 'is-active')
            } else if (importModalFoot !== null) {
              (importModalFoot as HTMLElement).innerText = 'Erreur de format de fichier'
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
    reader.send(null)
  }
}
function importQCMFromText (qcms: QcmsList, event: Event): void {
  if (event.target !== null) {
    const importModalFoot = document.querySelector('#qcm-import-qcm .has-text-danger')
    const input = event.target as HTMLInputElement
    if (input.value !== '') {
      let Titre = window.prompt('Donnez un nom au QCM :')
      if (Titre === null) {
        Titre = 'QCM importé'
      }
      const qcm = qcms.importQcm(input.value, Titre)
      if (qcm !== undefined) {
        listQcms()
        selectedQcmId = qcms.list.length - 1
        displayQcmEditor()
        qcms.save()
        utils.DOM.removeClass('qm-import-qcm', 'is-active')
      } else if (importModalFoot !== null) {
        (importModalFoot as HTMLElement).innerText = 'Erreur de format de fichier'
      }
    }
  }
}
</script>

<div class="modal" id="qm-import-qcm">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Import de QCM</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-import-qcm"></button>
    </header>
    <section class="modal-card-body">
      <label class="label">Fichier à importer depuis l'ordinateur</label>
      <div class="file is-centered">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            id="import-qcm-file"
            name="resume"
            accept=".txt"
            on:change={(event) => { openQCMFile($qcms, event) }}
          >
          <span class="file-cta">
            <span class="file-icon">
              <i class="ri-upload-2-line"></i>
            </span>
            <span class="file-label">
              Fichier…
            </span>
          </span>
          <span class="file-name">Fichier txt</span>
        </label>
      </div>
      <label class="label">Fichier en ligne</label>
      <input
        type="text"
        id="import-qcm-url"
        class="input"
        placeholder="Coller l'adresse internet du fichier"
        on:input={(event) => { importOnlineFile($qcms, event) }}
      >
      <label class="label">Contenu de fichier copié/collé</label>
      <input
        type="text"
        id="import-qcm-text"
        class="input"
        placeholder="Coller ici les données"
        on:input={(event) => { importQCMFromText($qcms, event) }}
      >
    </section>
    <footer class="modal-card-foot">
      <a href="blabla.html">Importer des qcm ?</a>
      <span class="has-text-danger"></span>
    </footer>
  </div>
</div>
