import storage from '../services/storage.js'
import Answer from './answer.js'

// a question has a content and a max of 4 answers
type questionType = 'text' | 'questionWithMedia' | 'answersWithMedia' | 'questionAndAnswersWithMedia'
type displayModes = 'classic' | 'imageAbove' | 'imageAside' | 'imagesInAnswers' | 'answerWithoutContent'

function uuidv4 (): string {
  return (String([1e7]) + String(-1e3) + String(-4e3) + String(-8e3) + String(-1e11)).replace(/[018]/g, c => (Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16)
  )
}

/* eslint-disable @typescript-eslint/space-before-blocks */
export default class Question {
  private _content: string // chapeau de la question
  private _source: string // qcmcam ou MD
  private _type: questionType // qcmcam2
  private _answers: Answer[] // liste des réponses
  private _displayMode: displayModes // mise en forme graphique
  private _shuffleMode: boolean // autoriser à melanger les résponses
  private _multipleAnswerMode: boolean // reponse multiple ou pas
  private _timedMode: boolean // chronomètrée ou pas
  private _duration: number // durée en secondes du temps d'affichage de la question
  private _textSize: number // taille du texte
  private readonly _id: string

  constructor (content: string, id?: string) {
    this._id = id === undefined ? uuidv4() : id
    this._content = content
    this._source = ''
    this._answers = []
    this._type = 'text'
    this._displayMode = 'classic'
    this._shuffleMode = true // shuffle autorisé par défaut
    this._multipleAnswerMode = false
    this._timedMode = false
    this._duration = 0
    this._textSize = 1
  }

  addAnwser (answer: string): Answer {
    if (this._answers.length > 3) alert('Attention, il y a déjà 4 réponses enregistrées\nPenser à supprimer !')
    const theAnswer = new Answer(answer)
    if (!this._answers.includes(theAnswer)) {
      this._answers.push(theAnswer)
    }
    this.save()
    return theAnswer
  }

  removeAnswer (id: number): void {
    if (this._answers[id] !== undefined) this._answers.splice(id, 1)
    this.save()
  }

  moveAnswerToTop (id: number): void {
    this.moveAnswer(id, 'top')
  }

  moveAnswerToBottom (id: number): void {
    this.moveAnswer(id, 'bottom')
  }

  moveAnswerUp (id: number): void {
    this.moveAnswer(id, 'moveup')
  }

  moveAnswerDown (id: number): void {
    this.moveAnswer(id, 'movedown')
  }

  getGoodAnswer (): number[] {
    const goodAnswers = []
    for (let i = 0; i < this._answers.length; i++) {
      if (this._answers[i].isCorrect) goodAnswers.push(i)
    }
    return goodAnswers
  }

  export (): object {
    return {
      content: this._content,
      type: this._type,
      shuffleMode: this._shuffleMode,
      answers: this.exportAnswers()
    }
  }

  save (): void {
    if (storage.isAvailable()) {
      storage.db.store_questions.put({ data: this, uid: this._id })
    }
  }

  static delete (uid: string): boolean {
    if (storage.isAvailable()) {
      storage.db.store_questions.delete(uid)
      return true
    } else return false
  }

  static async duplicate (uid: string): Promise<string> {
    if (storage.isAvailable()) {
      const newUid = uuidv4()
      const question = await storage.db.store_questions.get(uid)
      if (question !== undefined) {
        storage.db.store_questions.put({ data: question.data, uid: newUid })
        return newUid
      } else return ''
    } else return ''
  }

  static async load (uid: string): Promise<Question> {
    if (storage.isAvailable()) {
      const result = await storage.db.store_questions.get(uid)
      if (result !== undefined) {
        return this.remake(result.data)
      } else return new Question('Nouvelle question')
    } else return new Question('Nouvelle question')
  }

  copy (): Question {
    const newQuestion = new Question(this.content)
    newQuestion.source = this.source
    newQuestion.answers = this.answers
    newQuestion.type = this.type
    newQuestion.displayMode = this.displayMode
    newQuestion.shuffleMode = this.shuffleMode
    newQuestion.multipleAnswerMode = this.multipleAnswerMode
    newQuestion.timedMode = this.timedMode
    newQuestion.duration = this.duration
    newQuestion.textSize = this.textSize
    return newQuestion
  }

  zoomIn (): void {
    if (isNaN(this._textSize)) this.textSize = 1
    let coeff = 1.1
    if (this._textSize > 2) coeff = 1.2
    else if (this._textSize < 0.5) coeff = 1.05
    this._textSize = Math.round(this._textSize * coeff * 1000) / 1000
    if (Math.abs(this.textSize - 1) < 0.05) this.textSize = 1
  }

  zoomOut (): void {
    if (isNaN(this._textSize)) this.textSize = 1
    let coeff = 0.9
    if (this._textSize > 2) coeff = 0.8
    else if (this._textSize < 0.5) coeff = 0.95
    this._textSize = Math.round(this._textSize * coeff * 1000) / 1000
    if (Math.abs(this.textSize - 1) < 0.05) this.textSize = 1
  }

  static importQcmV1 (json: { question: string, reponse?: string }): Question | undefined {
    let question: Question
    const exactAnswers = []
    let questionHTML: HTMLElement
    if (typeof json.reponse === 'string') {
      const answers: string[] = json.reponse.split(',')
      if (answers.includes('A')) exactAnswers[0] = true
      if (answers.includes('B')) exactAnswers[1] = true
      if (answers.includes('C')) exactAnswers[2] = true
      if (answers.includes('D')) exactAnswers[3] = true
    }
    if (json.question !== undefined) {
      question = new Question('')
      questionHTML = document.createElement('div')
      questionHTML.innerHTML = json.question
      questionHTML.querySelectorAll('li').forEach(el => {
        question.addAnwser(el.innerHTML)
      })
      questionHTML.querySelectorAll('ol').forEach(el => {
        el.parentNode?.removeChild(el)
      })
      question.content = questionHTML.innerHTML
      for (let i = 0; i < 4; i++) {
        if (exactAnswers[i]) question._answers[i].isCorrect = true
      }
      question.save()
      return question
    }
    return undefined
  }

  static remake (question: Question): Question {
    const newQuestion = new Question(question._content, question._id)
    newQuestion.answers = Answer.remake(question._answers)
    newQuestion.type = question._type
    newQuestion.shuffleMode = question._shuffleMode
    newQuestion.displayMode = question._displayMode
    newQuestion.multipleAnswerMode = question._multipleAnswerMode
    newQuestion.timedMode = question._timedMode
    newQuestion.duration = question._duration
    newQuestion.textSize = question._textSize
    return newQuestion
  }

  private exportAnswers (): object[] {
    const answers = []
    for (const answer of this._answers) {
      answers.push({ content: answer.content, correct: answer.isCorrect })
    }
    return answers
  }

  private moveAnswer (id: number, direction: string): void {
    if (this._answers[id] !== undefined) {
      const answer = this._answers[id]
      this._answers.splice(id, 1)
      if (direction === 'moveup' && id > 0) {
        this._answers.splice(id - 1, 0, answer)
      } else if (direction === 'movedown' && id < this._answers.length - 1) {
        this._answers.splice(id + 1, 0, answer)
      } else if (direction === 'top') {
        this._answers.splice(0, 0, answer)
      } else if (direction === 'bottom') {
        this._answers.push(answer)
      }
      this.save()
    }
  }

  get id (): string {
    return this._id
  }

  get answers (): Answer[] {
    return this._answers
  }

  set answers (answers: Answer[]) {
    this._answers = answers
  }

  get source (): string {
    return this._source
  }

  set source (source: string) {
    this._source = source
  }

  get content (): string {
    return this._content
  }

  set content (content: string) {
    this._content = content
  }

  get type (): questionType {
    return this._type
  }

  set type (type: questionType) {
    this._type = type
  }

  set displayMode (displayMode: displayModes) {
    this._displayMode = displayMode
  }

  get displayMode (): displayModes {
    return this._displayMode
  }

  set shuffleMode (shuffle: boolean) {
    this._shuffleMode = shuffle
  }

  get shuffleMode (): boolean {
    return this._shuffleMode
  }

  set multipleAnswerMode (simpleAnswer: boolean) {
    this._multipleAnswerMode = simpleAnswer
  }

  get multipleAnswerMode (): boolean {
    return this._multipleAnswerMode
  }

  set duration (duration: number) {
    this._duration = duration
  }

  get duration (): number {
    return this._duration
  }

  set timedMode (timed: boolean) {
    this._timedMode = timed
  }

  get timedMode (): boolean {
    return this._timedMode
  }

  get textSize (): number {
    return this._textSize
  }

  set textSize (size: number) {
    this._textSize = size
  }
}
