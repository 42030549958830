<script lang="ts">

</script>

<div class="modal" id="qm-connect">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Connexion à QCMCam</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-connect"></button>
    </header>
    <div class="modal-card-body">
      <label class="is-small">Identifiant</label>
      <div class="control has-icons-left has-icons-right">
        <input type="email" class="input" placeholder="mail ou identifiant">
        <span class="icon is-left">
          <i class="ri-mail-line"></i>
        </span>
        <span class="icon is-right">
          <i class="ri-check-fill"></i>
        </span>
      </div>
      <label class="is-small">Mot de passe</label>
      <div class="control has-icons-left">
        <input type="password" class="input" placeholder="●●●●●●●●">
        <span class="icon is-left">
          <i class="ri-lock-line"></i>
        </span>
      </div>
      <div class="has-text-centered mt-4">
        <button class="button is-white">
          <a href="forgotpassword.html">Mot de passe oublié ?</a>
        </button>
        <button class="button">Valider</button>
      </div>
    </div>
    <footer class="modal-card-foot">
      <div class="has-text-centered container">
        Pas de compte ?
        <button class="button ml-5 is-vcentered" id="newuserToCreate">Créer un compte</button>
      </div>
    </footer>
  </div>
</div>
