<script lang="ts">

</script>

<div class="modal" id="waitAction">
  <div class="modal-background"></div>
  <div class="modal-content">
    <p class="image">
      <img src="images/ezgif-5-5ff9bd6048.png">
    </p>
  </div>
  <button class="modal-close is-large" aria-label="close"></button>
</div>
