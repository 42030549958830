<script lang="ts">

</script>

<div id="QCMCamShow" class="is-hidden">
  <header class="closeShowButton"><button class="delete closebutton" aria-label="close" id="close-show"></button>
  </header>
  <div id="participant-container0" class="box">
    <button class="button" id="btn-showNames0" title="Montrer/cacher les noms"><i class="ri-list-ordered-2"></i></button>
    <button class="button" id="btn-showGoodAnswers0" title="Montrer qui a juste"><i class="ri-check-line"></i></button>
    <button class="button" id="btn-showUserAnswers0" title="Montrer les réponses"><i class="ri-mail-open-line"></i></button>
    <button class="button" id="btn-selectOneUser0" title="Sélectionner un participant qui a répondu"><i class="ri-user-search-line"></i></button>
    <button class="button" id="btn-selectUserHasGood0" title="Sélectionner un participant qui a juste"><i class="ri-user-follow-line"></i></button>
    <button class="button" id="btn-selectUserHasBad0" title="Sélectionner un participant qui a faux"><i class="ri-user-unfollow-line"></i></button>
    <div id="participant-list0"></div>
  </div>
  <article id="commands-container" class="box has-text-centered">
    <span id="qcm-commands">
    <button class="button" id="btn-slide-hide" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button qcm-numero" id="qcm-numero"> 1 / 5 </button> <button class="button"
    id="btn-slide-next"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats"><i class="ri-bar-chart-2-line"></i></button>
      <button class="button" id="btn-slide-stop"><i class="ri-stop-large-line"></i></button></span>
  </article>
  <article id="camera-container" class="box">
    <img title="logo QCMCam" src="images/QCMCam-logo.png" alt="logo qcmcam" class="is-pulled-left">
    <canvas id="cameraCopy"></canvas>
  </article>
  <div id="qcm-commands0"><button class="button" id="btn-slide-hide0" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button" id="qcm-numero0"> 1 / 5 </button> <button class="button"
    id="btn-slide-next0"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer0"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats0"><i class="ri-bar-chart-2-line"></i></button>
    <button class="button" id="btn-slide-stop0"><i class="ri-stop-large-line"></i></button></div>
  <div id="qcm-container0" class="box qcm-container">
  </div>
  <div id="qcm-commands1"><button class="button" id="btn-slide-hide1" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button qcm-numero" id="qcm-numero1"> 1 / 5 </button> <button class="button"
    id="btn-slide-next1"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer1"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats"><i class="ri-bar-chart-2-line"></i></button>
    <button class="button" id="btn-slide-stop1"><i class="ri-stop-large-line"></i></button></div>
  <div id="qcm-container" class="box qcm-container">
  </div>
  <div id="participant-container" class="box">
    <button class="button" id="btn-showNames" title="Montrer/cacher les noms"><i class="ri-list-ordered-2"></i></button>
    <button class="button" id="btn-showGoodAnswers" title="Montrer qui a juste"><i class="ri-check-line"></i></button>
    <button class="button" id="btn-showUserAnswers" title="Montrer les réponses données"><i class="ri-mail-open-line"></i></button>
    <button class="button" id="btn-selectOneUser" title="Sélectionner un participant qui a répondu"><i class="ri-user-search-line"></i></button>
    <button class="button" id="btn-selectUserHasGood" title="Sélectionner un participant qui a juste"><i class="ri-user-follow-line"></i></button>
    <button class="button" id="btn-selectUserHasBad" title="Sélectionner un participant qui a faux"><i class="ri-user-unfollow-line"></i></button>
    <div id="participant-list"></div>
  </div>
</div>
