export const DEFAULT_VIEW = 'home'
export const viewValidKeys = <const>[DEFAULT_VIEW, 'start', 'generator', 'openusage', 'qcm', 'groups', 'statistics', 'diaporama-settings', 'QCMCamShow']
type ViewValidKeysType = typeof viewValidKeys
export type View = ViewValidKeysType[number]
export function isView (obj: unknown): obj is View {
  if (obj == null || typeof obj !== 'string') return false
  return viewValidKeys.includes(obj as View)
}
export function isViews (obj: unknown[]): obj is View[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isView)
}

export const qcmActionValidKeys = <const>['select', 'edit']
type QcmActionValidKeysType = typeof qcmActionValidKeys
export type QcmAction = QcmActionValidKeysType[number]
export function isQcmAction (obj: unknown): obj is QcmAction {
  if (obj == null || typeof obj !== 'string') return false
  return qcmActionValidKeys.includes(obj as QcmAction)
}
export function isQcmActions (obj: unknown[]): obj is QcmAction[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isQcmAction)
}

const actionValidKeys = ['', ...qcmActionValidKeys]
export type Action = '' | QcmAction
export function isAction (obj: unknown): obj is Action {
  if (obj == null || typeof obj !== 'string') return false
  return actionValidKeys.includes(obj as Action)
}
export function isActions (obj: unknown[]): obj is Action[] {
  if (obj == null || !Array.isArray(obj)) return false
  return obj.every(isAction)
}
