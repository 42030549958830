<script lang="ts">
  import ModalGroupMaker from './ModalGroupMaker.svelte'
  import ModalImportGroups from './ModalImportGroups.svelte'

</script>

<div id="qm-groups" class="is-hidden tab is-flex">
  <div>
    <aside class="menu mx-2">
      <p class="menu-label">Vos groupes</p>
      <ul class="menu-list" id="groups-list">
        <li>Pas de groupe</li>
      </ul>
      <p class="menu-label">Commandes</p>
      <ul class="menu-list">
        <li id="btn-add-group"><a><i class="ri-play-list-add-line"></i> Ajouter un groupe</a></li>
        <li id="btn-import-groups"><a><i class="ri-file-upload-line"></i> Importer des groupes</a></li>
        <li id="btn-download-groups"><a><i class="ri-file-download-line"></i> Télécharger</a></li>
        <!--<li id="btn-save-groups"><a><i class="ri-save-line"></i> Enregistrer</a></li>-->
      </ul>
    </aside>
  </div>
  <div class="p-3 is-flex-grow-1">
    <div class="p-3" id="students-list">
      <div class="py-6 is-size-3 has-text-grey-light has-text-weight-bold">
        <i class="ri-arrow-left-s-line"></i> Sélectionner un groupe pour voir/éditer ses éléments
      </div>
    </div>
  </div>
</div>
<ModalImportGroups />
<ModalGroupMaker />
