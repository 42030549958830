<script lang="ts">

</script>

<div class="modal" id="qm-gp-maker">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Créer des groupe</p>
      <button class="delete closebutton" aria-label="close" data-modal="gp-maker"></button>
    </header>
    <section class="modal-card-body">
      <div class="colums">
        <div class="column">
          <label class="label">Groupe A</label>
          <div id="gpA-container"></div>
        </div>
        <div class="column">
          <label class="label">Groupe B</label>
          <div id="gpB-container"></div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success is-outlined mx-2" id="gp-maker-random">Aléatoire</button>
      <button class="button is-primary is-outlined mx-2" id="gp-maker-collrall">Appel</button>
      <button class="button is-danger is-outlined mx-2" id="gp-maker-cancel">Annuler</button>
      <button class="button is-link mx-2" id="gp-maker-confirm">Valider</button>
    </footer>
  </div>
</div>
