<script lang="ts">
  import { onMount } from 'svelte'
  import utils from '../../../lib/ts/services/utils'
  import Qcm from '../../../lib/ts/models/qcm'
  import storage from '../../../lib/ts/services/storage'
  import type Answer from '../../../lib/ts/models/answer'

  export let qcms
  export let index
  export let question
  export let listQcms: () => void
  export let updateSelectedQcmId: (id: number) => void
  export let displayQcmEditor: () => void

  let id: string
  let title: string
  let questionContent: string
  let answersList: Answer[] = []

  onMount(() => {
    id = qcms.list[index][0]
    title = qcms.list[index][1]
    if (question) {
      questionContent = question.content
      answersList = [...question.answers]
      if (question.shuffleMode) utils.array.shuffle(answersList)
    }
  })

  const editQcm = () => {
    try {
      storage.db.store_data.put({ uid: 'QCMCamlatestSelectedQCM', data: id })
      updateSelectedQcmId(index)
      displayQcmEditor()
    } catch (e) {
      alert('Erreur lors du chargement du QCM')
      console.warn(e)
    }
  }

  const copyQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const newQcm = await qcm.copy()
      await newQcm.save()
      qcms.list.push([newQcm.id, newQcm.name, newQcm.questionsList[0], newQcm.questionsList.length])
      await qcms.save()
      listQcms()
    } catch (err) {
      console.error('Erreur de copie', err)
    }
  }

  const downloadQcm = async () => {
    try {
      const qcm = await Qcm.load(id)
      const content = await qcm.export()
      const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })
      utils.file.download(blob, `${qcm.name}.txt`)
    } catch (err) {
      console.warn('oupsi export qcm !', err)
    }
  }

  const deleteQcm = async () => {
    if (confirm(`Vous allez supprimer le qcm \n${title}\nConfirmez-vous ?`)) {
      await qcms.deleteQcmById(id)
      await qcms.save()
      listQcms()
    }
  }
</script>

<article class="card m-4">
  <div class="media mb-4">
    <div class="media-left">
      <i class="ri-file-line title is-3"></i>
    </div>
    <div class="media-content">
      <p class="title">{title}</p>
    </div>
  </div>
  <div class="card-content p-4">
    <div class="question-container">
      {#if question}
        <div class="question {question.displayMode}" style="font-size: {question.textSize}em">
          <header class="question-content {question.type}">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            <div>{@html questionContent}</div>
          </header>
          <ol class="answers {question.type}">
            {#each answersList as answer}
              <li class="answer {question.type}" class:is-correct={answer.isCorrect}>
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                <div>{@html answer.content}</div>
              </li>
            {/each}
          </ol>
        </div>
      {/if}
    </div>
  </div>
  <footer class="card-footer">
    <a class="card-footer-item" title="Modifier" on:click={editQcm}>
      <i class="ri-edit-line"></i>
    </a>
    <a class="card-footer-item" title="Dupliquer" on:click={copyQcm}>
      <i class="ri-file-copy-line"></i>
    </a>
    <a class="card-footer-item" title="Exporter" on:click={downloadQcm}>
      <i class="ri-file-download-line"></i>
    </a>
    <a class="card-footer-item" title="Supprimer" on:click={deleteQcm}>
      <i class="ri-delete-bin-line"></i>
    </a>
  </footer>
</article>
