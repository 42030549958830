<script lang="ts">
  
</script>

<div class="modal" id="qm-newuser">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Inscription à QCMCam</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-newuser"></button>
    </header>
    <div class="modal-card-body">
      <label class="is-small">Adresse mail</label>
      <div class="control has-icons-left has-icons-right">
        <input type="email" class="input" placeholder="mail ou identifiant">
        <span class="icon is-left">
          <i class="ri-mail-line"></i>
        </span>
        <span class="icon is-right">
          <i class="ri-check-fill"></i>
        </span>
      </div>
      <label class="is-small">Choisir votre mot de passe</label>
      <div class="control has-icons-left">
        <input type="password" class="input" placeholder="●●●●●●●●">
        <span class="icon is-left">
          <i class="ri-lock-line"></i>
        </span>
      </div>
      <div class="is-small mt-4 mb-4">
        <input type="checkbox" class="checkbox">
        J'ai lu les <a href="cgu.html">conditions d'utilisation de QCMCam</a> et les accepte sans
        restriction.
      </div>
      <button class="button">Valider</button>
    </div>
  </div>
</div>
