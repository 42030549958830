import Qcm from './qcm.js'
import storage from '../services/storage.js'
import Question from './question.js'

// [ qcm Id, qcm Name, qcm 1rst question Id, nb of questions ]
type entry = [string, string, string, number]

export default class QcmList {
  private _list: entry[]

  constructor () {
    this._list = []
  }

  save (): void {
    if (storage.isAvailable()) {
      storage.db.store_data.put({ uid: 'QCMCamQcms', data: this })
    }
  }

  importQcm (content: string, name: string): Qcm | undefined {
    const qcm = Qcm.import(content, name)
    if (qcm !== undefined) {
      qcm.save()
      this._list.push([qcm.id, qcm.name, qcm.questionsList[0], qcm.questionsList.length])
      return qcm
    } else return undefined
  }

  importQcmList (textContent: string, filename: string): boolean {
    if (textContent === '') return false
    try {
      const json = JSON.parse(textContent)
      if (json.uid === 'QCMCamQcms') {
        for (const entry of json.data._list) {
          // entrée déjà dans la liste
          if (this.list.filter(o => o[1] === entry[0]).length === 1) continue
          this._list.push(entry)
        }
        this.save()
        for (const qcm of json.qcms) {
          Qcm.import(qcm, qcm._name)
        }
        return true
      } else return false
    } catch (e) {
      console.warn('Erreur d\'import de la liste des qcms de ' + filename)
      return false
    }
  }

  removeQcmReference (id: number): boolean {
    if (this._list[id] !== undefined) {
      this._list.splice(id, 1)
      this.save()
      return true
    } else return false
  }
  /** à revoir car il faut supprimer les questions aussi */

  async deleteQcmById (qcmId: string): Promise<boolean> {
    let retour = true
    for (const [key, qcm] of this._list.entries()) {
      if (qcm[0] === qcmId) {
        this._list.splice(key, 1)
        retour = await Qcm.delete(qcmId)
        break
      }
    }
    return retour
  }

  getQcmIndex (qcmId: string): number | undefined {
    for (const [key, qcm] of this._list.entries()) {
      if (qcm[0] === qcmId) return key
    }
  }

  async getMiniatures (): Promise<Question[]> {
    const miniatures: Question[] = []
    for (const [index, datas] of this._list.entries()) {
      if (datas[2] === undefined) {
        // le qcm ne comporte pas de question ! => suppression
        await this.deleteQcmById(datas[0])
        this._list.splice(index, 1)
        this.save()
      } else {
        await Question.load(datas[2]).then(question => { miniatures.push(question) }).catch(err => {
          console.error('Erreur de récupération de vignette', err)
          // correction de l'erreur par suppression de la référence.
          this._list.splice(index, 1)
          this.save()
        })
      }
    }
    return miniatures
  }

  updateQcm (qcm: Qcm): void {
    for (const [index, qcmEntry] of this.list.entries()) {
      if (qcm.id === qcmEntry[0]) {
        this.list[index] = [qcm.id, qcm.name, qcm.questionsList[0], qcm.questionsList.length]
        this.save()
        break
      }
    }
  }

  static remake (qcms: QcmList): entry[] {
    const newQcms = new QcmList()
    for (const qcmData of qcms._list) {
      newQcms._list.push(qcmData)
    }
    return newQcms.list
  }

  async export (): Promise<Blob> {
    const content: { uid: string, data: QcmList, qcms: Qcm[] } = { uid: 'QCMCamQcms', data: this, qcms: [] }
    for (const qcm of this.list) {
      const theQcm = await Qcm.load(qcm[0])
      content.qcms.push(await theQcm.export())
    }
    const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' })
    return blob
  }

  get list (): entry[] {
    return this._list
  }

  set list (qcms: entry[]) {
    this._list = qcms
  }
}
