<script lang="ts">

</script>

<div class="modal" id="qm-callroll">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Appel</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-callroll"></button>
    </header>
    <section class="modal-card-body">
      <label class="is-small">Présents</label>
      <div id="presents-container"></div>
      <label class="is-small">Absents</label>
      <div id="absents-container"></div>
    </section>
    <footer class="modal-card-foot is-justify-content-center">
      <button class="button is-light is-large" id="btn-start-qcm-after-callroll">
        <img src="images/play-circle-line.svg" alt="Play button">
      </button>
    </footer>
  </div>
</div>
