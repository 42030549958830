import Question from './question.js'
import storage from '../services/storage.js'

// un qcm est un ensemble de questions
// qcmType indique la façon dont est édité/enregistré le qcm (code)
// qcmcam1 devra être converti en questionnaire qcmcam2
type qcmType = 'qcmcam1' | 'markdown' | 'qcmcam2'

function uuidv4 (): string {
  return (String([1e7]) + String(-1e3) + String(-4e3) + String(-8e3) + String(-1e11)).replace(/[018]/g, c => (Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16)
  )
}

export default class Qcm {
  private _name: string
  private _type: qcmType
  private _questionsList: string[]
  private readonly _id: string
  private _coloredAnswers: boolean
  private _colors: string[]
  private _vignette: string
  private _questions: Question[]

  constructor (name: string, type: qcmType, id?: string) {
    this._name = name
    this._type = type
    this._id = id === undefined ? uuidv4() : id
    this._questionsList = []
    this._coloredAnswers = false
    this._colors = [ '', '', '', '' ]
    this._vignette = ''
    this._questions = []
  }

  addQuestion (question: Question): void {
    this._questionsList.push(question.id)
  }

  createQuestion (text: string): Question {
    const question = new Question(text)
    this._questionsList.push(question.id)
    return question
  }

  removeQuestion (id: number): string[] {
    if (this._questionsList[id] !== undefined) {
      Question.delete(this._questionsList[id])
      this._questionsList.splice(id, 1)
      // supression de la question dans la base de données
    }
    return this._questionsList
  }

  moveQuestionUp (id: number): string[] {
    if (this._questionsList[id] !== undefined || id > 0) {
      const question = this._questionsList[id]
      this._questionsList.splice(id, 1)
      this._questionsList.splice(id - 1, 0, question)
    }
    return this._questionsList
  }

  moveQuestionDown (id: number): string[] {
    if (this._questionsList[id] !== undefined || id < this._questionsList.length - 1) {
      const question = this._questionsList[id]
      this._questionsList.splice(id, 1)
      this._questionsList.splice(id + 1, 0, question)
    }
    return this._questionsList
  }

  moveQuestionOnTop (id: number): string[] {
    if (this._questionsList[id] !== undefined || id > 0) {
      const question = this._questionsList[id]
      this._questionsList.splice(id, 1)
      this._questionsList.splice(0, 0, question)
    }
    return this._questionsList
  }

  moveQuestionToBottom (id: number): string[] {
    if (this._questionsList[id] !== undefined || id < this._questionsList.length - 1) {
      const question = this._questionsList[id]
      this._questionsList.splice(id, 1)
      this._questionsList.push(question)
    }
    return this._questionsList
  }

  async getQuestion (id: number): Promise<Question | undefined> {
    return await Question.load(this._questionsList[id]).then(
      question => { return question }
    ).catch(err => {
      console.warn('Erreur de lecture de question', err)
      return undefined
    })
  }

  save (): void {
    if (storage.isAvailable()) {
      storage.db.store_qcms.put({ data: this, uid: this._id })
    }
  }

  async copy (): Promise<Qcm> {
    const newQcm = new Qcm('Copie de ' + this.name, this.type)
    // copie des questions
    for (const qid of this.questionsList) {
      await Question.load(qid).then(question => { const newQuestion = question.copy(); newQuestion.save(); return newQuestion }).then(questionCopy => { newQcm.questionsList.push(questionCopy.id) }).catch(error => { console.error(error) })
    }
    newQcm.coloredAnswers = this.coloredAnswers
    newQcm.colors = this.colors
    newQcm.vignette = this.vignette
    return newQcm
  }

  static async load (id: string): Promise<Qcm> {
    const result = await storage.db.store_qcms.get(id)
    if (result !== undefined) return Qcm.remake(result.data)
    else return new Qcm('Qcm vide', 'qcmcam2')
  }

  static getById (qcms: Qcm[], id: string): Qcm | undefined {
    for (const qcm of qcms) {
      if (qcm.id === id) return qcm
    }
    return undefined
  }

  // delete the qcm from database
  static async delete (id: string): Promise<boolean> {
    // delete all questions
    const retour = await Qcm.load(id).then(qcm => {
      for (const qid of qcm.questionsList) {
        storage.db.store_questions.delete(qid)
      }
      storage.db.store_qcms.delete(id)
      return true
    }).catch(err => { console.error('Erreur en supprimant le QCM', err); return false })
    return retour
  }

  async loadQuestions (): Promise<Question[]> {
    const questions: Question[] = []
    for (const id of this.questionsList) {
      const question = await Question.load(id)
      questions.push(question)
    }
    return questions
  }

  static import (thingToImport: string | importedObject, filename: string | undefined): Qcm {
    if (thingToImport === '') return new Qcm('Qcm Vide', 'qcmcam2')
    try {
      if (typeof thingToImport === 'object') {
        if (thingToImport.type === 'qcmcam2') {
          Qcm.remake(thingToImport.qcm).save()
          for (const question of thingToImport.questions) {
            Question.remake(question).save()
          }
        }
      } else {
        const json = JSON.parse(thingToImport)
        if (json !== null) {
          if (json.type === 'qcmcam2') {
            const qcm = Qcm.remake(json.qcm)
            qcm.save()
            for (const question of json.questions) {
              Question.remake(question).save()
            }
            return qcm
          } else if (json[0] !== undefined && filename !== undefined) { // qcm v1 found
            let count = 0
            let name = filename
            if (filename.includes('.')) name = filename.split('.').slice(0, -1).join('.')
            const qcm = new Qcm(name, 'qcmcam2')
            while (json[count] !== undefined) {
              const question = Question.importQcmV1(json[count])
              if (question !== undefined) qcm.addQuestion(question)
              count++
            }
            return qcm
          }
        }
      }
    } catch (e) {
      console.warn('Fichier non pris en charge')
      console.log('Parsing error', e)
      return new Qcm('Qcm Vide', 'qcmcam2')
    }
    return new Qcm('Qcm Vide', 'qcmcam2')
  }

  static remake (qcm: Qcm): Qcm {
    const newQcm = new Qcm(qcm._name, qcm._type, qcm._id)
    newQcm.coloredAnswers = qcm._coloredAnswers
    newQcm.colors = qcm._colors
    newQcm.vignette = qcm._vignette
    newQcm.questionsList = qcm._questionsList
    return newQcm
  }

  async export (): Promise<any> {
    const questions = await this.loadQuestions()
    const returnObj = { qcm: this, type: 'qcmcam2', questions }
    return returnObj
  }

  get name (): string {
    return this._name
  }

  set name (name) {
    this._name = name
  }

  get type (): qcmType {
    return this._type
  }

  set type (type: qcmType) {
    this._type = type
  }

  get id (): string {
    return this._id
  }

  get questionsList (): string[] {
    return this._questionsList
  }

  set questionsList (questions: string[]) {
    this._questionsList = questions
  }

  get coloredAnswers (): boolean {
    return this._coloredAnswers
  }

  set coloredAnswers (truefalse: boolean) {
    this._coloredAnswers = truefalse
  }

  get colors (): string[] {
    return this._colors
  }

  set colors (arrayOfFourColors: string[]) {
    this._colors = arrayOfFourColors
  }

  get vignette (): string {
    return this._vignette
  }

  set vignette (base64Image: string) {
    this._vignette = base64Image
  }

  get questions (): Question[] {
    return this._questions
  }

  set questions (questions: Question[]) {
    this._questions = questions
  }
}

interface importedObject {
  type: string
  qcm: Qcm
  questions: any[]
}
