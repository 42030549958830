import Dexie from 'dexie'

const db = new Dexie('db_qcmcam2')
db.version(4).stores({
  store_data: 'uid,data',
  store_questions: 'uid,data',
  store_qcms: 'uid,data',
  store_settings: 'uid,data',
  store_sessions: '++uid,data',
  store_students: '++uid,data',
  store_groups: '++uid,data',
  links_groups_students: '++uid,groupid,studentid'
})
export const storage = {
  isAvailable (): boolean {
    if (this.db === null) return false
    return true
  },
  db,
  session: {
    get (key: sessionValueName) {
      const value = sessionStorage.getItem(key)
      if (value !== null) return value
    },
    set (key: string, value: string) {
      sessionStorage.setItem(key, value)
    }
  }
}

type sessionValueName = string
export default storage
